.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 320px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .card {
    max-width: 100%;
  }
}

.dark {
  background-color: #444;
  color: #ffffff;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.cardContent {
  padding: 24px;
}

.cardTitle {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.cardExcerpt {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.cardMeta {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

.cardMeta span {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}

.cardLink {
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 8px;
  background-color: #4b7bec;
  transition: all 0.3s ease-in-out;
}

.cardLink:hover {
  background-color: #3867d6;
}

.dark .cardLink {
  background-color: #00030a;
  color: #fc00e3;
}

.buttonContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: grid;
}