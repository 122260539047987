/* src/components/ProjectsSection.css */


.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 20px 20px 20px;
  gap: 24px;
  
}

.dark  {

}



@media (max-width: 768px) {
  .projectsContainer {
    flex-direction: column;
  }
  .projects {
    padding: 0;
  }
}

  