.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 24px;
  width: 280px;
  background-color: white;
}

.title {
  font-size: 24px;
  margin-bottom: 5px;
}

.link {
  color: #1976d2;
  font-weight: 500;
  margin-bottom: 8px;
  text-decoration: none;
}

.description {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
}

.technologies,
.features {
  font-size: 14px;
  margin-bottom: 5px;
}

.technologies h4,
.features h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.technologies li,
.features li {
  list-style-type: disc;
  margin-left: 20px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px;
  gap: 24px;
}

@media (max-width: 768px) {
  .card {
    margin: 16px 0;
    padding: 15px;
    width: 100%;
  }
}

.dark {
  background-color: #444;
  border: solid 1px #9b9b9b;
  color: white;
}

.dark h3 {
  color: #fc00e3;
}

.dark li {
  color: white;
}
