/* src/components/Navbar/Navbar.module.css */

.navbar {
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 2px 0 3px rgb(0 0 0 / 34%);
  z-index: 100;
  transition: all 0.3s;
  width: 200px;
  height: 100vh;
  position:fixed;
}

.imgLogoHome{
  display: block;
  text-align: center;
}

.menu_item a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  position: relative;
}

.menu_item a::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 5px;
  bottom: 0;
  left: -8%;
  top: 24px;
  background-color: #b042b4;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.menu_item a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

 a,ul, li a{
    color: black;
  }
  
  .dark {
    background-color: #2c2c2c;
  }

  .dark a{
    color: white !important;
  }

  .dark label{
    color: white !important;
  }

.menuButton:hover{
  background: initial !important;
}


  a {
    text-decoration: none;
  }
  
  .logo {
    width: 110px;
    transition: transform 0.5s ease-in-out;
  }

  .logoMini{
    width: 110px;
    transition: transform 0.5s ease-in-out;
  }

  .titleMain {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  .titleMain a{
    display: grid;
    text-align: center;
  }

  .titleMain a span{
    background: none;
  }

  .titleFirst{
    font-size: 1.8rem;
    color: #b042b4;
    font-weight: 400;
  }
  .titleFirstLogin{
    font-size: 1.3rem;
    color: #b042b4;
    font-weight: 400;
  }
  .titleSecond{
    font-size: 1rem;
  }
  .dark .titleLogin{
    color: white;
    background-color: #2c2c2c;
  }
  
  .menu_icon {
    display: none;
  }
  
  .bar1 {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #333;
    top: 10px;
    position: relative;
    transition: all 0.3s;
}

.bar3 {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #333;
  top: -4px;
  position: relative;
  transition: all 0.3s;
}

  .bar2 {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: all 0.3s;
  }
  
  .cross1 {
    transform: rotate(-45deg);
    top: 2px !important;
    transition: all 0.3s;
}

  .cross2 {
    transform: rotate(45deg);
    transition: all 0.3s;
  }

  .cross3 {
    display:none;
    transition: all 0.3s;
  }
  
  .menu {
    display: flex;
    align-items: center;
    list-style: none;
    transition: opacity 0.3s;
  }
  
  .open {
    opacity: 1;
    pointer-events: auto;
  }
  
  
  .menu_item a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
  }
  
  .menu_item label {
    font-size: 1rem;
  }
  
  .menu_item input[type='checkbox'] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }

.menuButton {
  display: none;
}

.menu {
  display: grid;
  list-style: none;
  transition: opacity 0.3s;
  gap: .5rem;
  margin: 0px 0px 0px -38px;
}

.iconMenu{
  margin-right: 10px;
}

.dark .iconMenu svg{
  color: #fc00e3;
  background: transparent !important;
}

.dark .iconMenu{
  background: transparent !important;
}

.logoMini{
  display: none;
}



/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .miniNav .titleLogin{
    display: none;
  }
  .miniNav{
    height: 0px !important;
    padding: 0 !important;
  }
  
  .miniNav .logo{
    display: none;
  }

  .miniNav .logoMini{
    display: block;
  }
  
  .miniNav .menuButton{
    transform: scale3d(1.5, 1.5, 1.5);
    box-shadow: 0 1px 16px rgb(0 0 0 / 44%);
    display: block !important;
    height: 60px;
    position: fixed;
    right: 16px;
    top: 14px;
    background-color: white !important;
    z-index: 2;
  }
  
  .miniNav .menuButton.open{
    right: 16px !important;
    left: auto;
  }
  
  .miniNav .titleMain{
    display: none;
  }
  
  .miniNav .menu{
    z-index: 1;
    margin-top: 0px;
  }
  
  .miniButton{
    transform: inherit !important;
  }
  
  .miniButton.open{
    left: auto;
  }
  
  .miniNav.dark .menuButton.open .cross1{
    background: #333 !important;
  }
  
  .miniNav.dark .menuButton.open .cross2{
    background: #333 !important;
  }
  
  .miniNav.dark .menuButton.open .cross3{
    background: #333 !important;
  }
  
  .dark .miniButton.open .cross1{
    background: #333 !important;
  }
  
  .dark .miniButton.open .cross2{
    background: #333 !important;
  }
  
  .miniNav.dark .menuButton .bar1{
    background: #333 !important;
  }
  
  .miniNav.dark .menuButton .bar2{
    background: #333 !important;
  }
  
  .miniNav.dark .menuButton .bar3{
    background: #333 !important;
  }
  
  
  .dark .miniButton .bar1{
    background: #333 !important;
  }
  
  .dark .miniButton .bar2{
    background: #333 !important;
  }
  
  .dark .miniButton .bar3{
    background: #333 !important;
  }



  .switch_label {
margin-top: 5px !important;
  }
  

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 1px 16px rgb(0 0 0 / 44%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 80px;
    width: inherit;
  }

  .logo {
    width: 50px;
    margin-right: 0px;
  }

  .niniLogo{
    width: 110px;
    transition: transform 0.5s ease-in-out;
  }



  .menu {
    margin-top: 3px;
    position: absolute;
    top: 97%;
    left: -120%;
    background-color: #ebe0eb;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    gap: 1.5rem;
    padding: 1rem 0rem 1rem 0rem;
    box-shadow: 0 6px 11px rgb(0 0 0 / 46%);
    transition: all .3s !important;
  }

  .dark span{
    background-color: white;
  }



  .dark ul{
    background-color: #3e3c3c;
    border-bottom: solid 2px white;
    box-shadow: 0 6px 12px rgb(0 0 0);
  }

  .menuButton {
    display: initial !important;
    margin-top: initial !important;
  }

  .menu_icon {
    display: initial !important;
  }


  .open {
    display: flex;
    pointer-events: auto;
    transition: 3s;
    transition: all .3s !important;
    left: 0%;
    margin-left: initial;
  }
}

/* src/components/Navbar.module.css */

.menuButton {
  display: none;
  position: relative;
  width: 60px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

.menuButton .bar {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: transform 0.3s, opacity 0.3s;
}

.menuButton .bar:nth-child(1) {
  top: 0;
}

.menuButton .bar:nth-child(2) {
  top: 11px;
}

.menuButton .bar:nth-child(3) {
  top: 22px;
}

.menuButton.open .bar:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.menuButton.open .bar:nth-child(2) {
  opacity: 0;
}

.menuButton.open .bar:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

/* ... */

/* src/components/Navbar.module.css */

.switch {
  display: inline-block;
  position: relative;
}

.switch_input {
  display: none;
}

.switch_label {
  display: block;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.switch_label::before {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: white;
  transition: all 0.3s;
}

.switch_input:checked + .switch_label {
  background-color: #4caf50;
}

.switch_input:checked + .switch_label::before {
  transform: translateX(30px);
}

/* src/components/Navbar.module.css */

.moon_icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7px;
  left: 7px;
  transition: all 0.3s;
}

.switch_input:checked + .switch_label .moon_icon {
  transform: translateX(30px);
}

.hidden {
  display: none;
}

.sun_icon{
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7px;
  right: 7px;
  transition: all 0.3s;
  color: black;
}


.rotate {
  transform: rotate(360deg);
}

.logoutButton i:before {
  position: relative;
  font-weight: 800 !important;
  color: red;
  font-size: 1.7rem;
  cursor: pointer;
}

@media (max-width: 768px)  {
  .logoutButton i:before {
    position: relative;
    top: 7px;
    font-weight: 800 !important;
    color: red;
    font-size: 1.7rem;
  }
}

.menuLogin .itemPublic {
  display: none;
}

