/* src/styles/UserProfile.module.css */
.bendnull{
  margin-bottom: 0px !important;
}
.bstartnull {
  margin-top: 0px !important;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  margin: 1rem 0;
  font-size: 1.5rem;
}

.title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #6c757d;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.contact_item {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.contact_icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.description {
  text-align: center;
  margin-bottom: 1rem;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.skill {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  background-color: #6c757d;
  color: #ffffff;
  font-size: 0.875rem;
}


 .dark {
    background-color: #333;
  }

  .dark .card {
    background-color: #585656;
  } 

  .dark .name {
    color: #ffffff;
  }

  .dark .title {
    color: #ffffff;
  }

  .dark .contact_item {
    color: #ffffff;
  }

  .dark .contact_icon {
    fill: #ffffff;
  }

  .dark .description {
    color: #ffffff;
  }

  .dark .skill {
    background-color: #6c757d;
  }

@media screen and (max-width: 768px) {
  .card {
    padding: 1rem;
  }

  .profile_image {
    width: 75px;
    height: 75px;
  }

  .name {
    font-size: 1.25rem;
  }

  .title {
    font-size: 1rem;
  }

  .contact_item {
    font-size: 0.875rem;
  }

  .contact_icon {
    width: 15px;
    height: 15px;
  }

  .description {
    font-size: 0.875rem;
  }

  .skills {
    gap: 0.25rem;
  }

  .skill {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}