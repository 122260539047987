/* src/components/ProjectsSection.css */


  .dark {
    background-color: #333;
  }


  .projectsContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.project {
  flex: 1;
  min-width: 300px;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dark .project {
  background-color: #444;
  color: white;
}

.dark h2{
  color: white;
}

@media (max-width: 768px) {
  .projectsContainer {
    flex-direction: column;
  }
  .projectsSection {
    margin-top: 30px !important;
  }
}

  