.repoCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  max-width: 370px;
  text-align: center;
  position: relative;
}

.cardContent {
  padding: 24px;
  margin-bottom: 30px;
}

.cardTitle {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-top: 0;
  margin-bottom: 8px;
}

.buttonContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: grid;
}

.dark {
  background-color: #444;
  border: solid 1px #9b9b9b;
}

.dark .repoCard a {
  color: #fc00e3;
}

.dark .cardLink {
  color: #fc00e3;
}

.repoCard h3 {
  margin-bottom: 8px;
}

.repoCard a {
  text-decoration: none;
}

.repoCard p {
  margin-bottom: 8px;
  text-align: center;
}

.repoCard span {
  font-size: 12px;
  margin-right: 8px;
}

.cardLink {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #4b7bec;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.dark .cardLink {
  background-color: #00030a;
}

@media screen and (max-width: 768px) {
  .repoCard {
    max-width: 100%;
  }
}
