.card {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-basis: 45%;
  margin: 20px;
  max-width: 300px;
  min-width: 200px;
  padding: 20px;
  text-align: center;
  }
  
  .card.dark {
  background-color: #444;
  border: solid 1px #9b9b9b;
  }
  
  .card.dark h3 {
  color: #fc00e3;
  }

  .card.dark .description{
    color: #ffffff;
  }
  
  .card .icon {
  font-size: 40px;
  margin-bottom: 20px;
  }
  
  .card .title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  }
  
  .card .description {
  color: #080808;
  font-size: 14px;
  line-height: 1.5;
  }
  
  @media (max-width: 768px) {
  .card {
  flex-basis: 100%;
  margin: 10px;
  max-width: 100%;
  }
  }
  