html {
  height: 100%;
  overflow: hidden;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  display: flex;
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}
li.cursor-pointer:hover {
  background: #c8fdfb;
}
@media screen and (max-width: 768px) {
  .App {
    height: 106vh;
    overflow: hidden;
  }
  section {
    padding:1rem 1rem 2rem 1rem !important;
  }
}

@media screen and (min-width: 768px) {
.loadingLeft :is(h5,p) {
  text-align: left;
}

.loadingLeft img{
  margin-left:0px !important
}

.loadingLeft{
  width:50%
}
}
section {
  background: #efefef;
  padding:1rem 3rem 2rem 3rem;
  overflow: auto;
  flex-grow: 1;
  margin-left: 200px;
}

.active {
  font-weight: bold;
  color: #000 !important;
  text-decoration: underline !important;
  text-underline-offset: 2px !important;
  text-decoration-thickness: 5px!important;
  text-decoration-color: #b042b4 !important;
  text-underline-offset: 6px !important;
  transition: all .3s;
}



.dark{
  background-color: #333;
}

.dark :is(h1,h2){
  color: #ebebeb;
}

.dark :is(h3, h4,h5){
  color: #c7c7c7;
}

.dark :is(p,span){
  color: #aeacac;
}



@media (max-width: 768px) {
  .content {
    margin-top: 80px;
  }
  section {
    margin-left: inherit;
    height: 100%;
  }
  .adjustMovil {
    height: 50px;
  }
  
}

@media screen and (max-width: 768px) {
  .table-responsive {
      border: 0;
  }

  .table-responsive table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.5em;
  }

  .table-responsive thead {
      display: none;
  }

  .table-responsive tr {
      display: block;
      margin-bottom: 0.625em;
      background-color: #f7f7f7;
      border-radius: 0.25em;
  }

  .table-responsive td {
      display: grid;
      justify-content: space-between;
      padding: 0.625em;
      border-bottom: 1px solid #ddd;
  }

  .table-responsive td:before {
      content: attr(data-label);
      flex-basis: 50%;
      font-weight: bold;
  }
}


.react-calendar {
  border: none !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  width: 100% !important;
}

.react-calendar__tile {
  background-color: #fff;
  border-radius: 10px;
  color: #333;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button {
  background-color: transparent;
  color: #333;
  font-size: 1em;
}



.react-calendar__month-view__days__day {
  height: 120px !important;
}


.react-calendar button {
  cursor: default !important;
}

.modalProyect .modal-content{
  padding: 20px;
}

.modalProyect span.close {
  position: absolute;
  right: 30px;
  font-size: 2.5rem;
  cursor: pointer;
  top: 0;
}



.contieneLoading {
  display: grid;
}

#imgLoading {
  margin-left: auto;
  margin-right: auto !important;
}

.textoLoading {
  overflow: hidden;
}

article#proyectDevCont {
  margin-top: -25px;
}

.tareaVencida {
  background-color: #ffd4d4 !important;
  color: #2f0000 !important;
}

.tareaLista {
  background-color: #d4ffd4 !important;
  color: #002f00 !important;
}

#historialAccordion button.accordion-button {
  background: #cbcbcb;
  color: black;
  font-weight: 600;
}

button.react-calendar__tile.react-calendar__month-view__days__day abbr {
  position: relative;
  top: -35px;
  left: -35px;
  font-size: .8rem;
}

button.react-calendar__tile.react-calendar__month-view__days__day.diaConTarea abbr {
  top: -8px;
  left: -35px;
  font-size: 1rem;
  font-weight: 700;
  color: #b042b4;
}

.eventCalendar {
  background: #7effa6;
  cursor: pointer;
}

.eventCalendar:hover {
  background: #24f565;
}

button.react-calendar__tile.react-calendar__month-view__days__day.diaConTarea:hover {
  background: none;
}

.react-calendar__tile:disabled:hover{
  background: #f0f0f0 !important;
}

.react-calendar__tile:hover{
  background:none !important;
}

.contentEvent {
  padding: 5px;
}