.dark {
    background-color: #272727;
  }

  .dark .card{
    border: solid red;
  }

  .dark label{
    color:white
  }
  