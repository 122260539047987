/* Estilos para la versión clara */
.btnSection {
    display: block;
    margin: 0 auto;
    padding: 1.5rem 2.5rem;
    border: none;
    border-radius: 5rem;
    background-color: #fff;
    color: #333;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btnSection:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .btnSection:active {
    transform: translateY(0px);
    box-shadow: none;
  }

  .dark{}
  
  /* Estilos para la versión oscura */
  .dark.btnSection {
    background-color: #000000;
    color: #fc00e3;
  }
  
  .dark.btnSection:hover {
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.15);
  }

  .contentMobile{
    display: none;
  }
  
  /* Estilos para la versión responsive */
  @media screen and (max-width: 768px) {
    .btnSection {
      padding: 1rem 2rem;
      font-size: 1.4rem;
    }
    
  .contentMobile{
    display: block;
  }
  }
  