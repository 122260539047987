/* src/components/Contact.module.css */
.contactContainer {
  max-width: 600px;
  text-align: center;
  background-color: #F4F4F4;
  border-radius: 15px;
  padding: 20px;
  border: solid 5px #b042b4;
  margin-top: -15px;
  }

.dark {
  background-color: #333;
}

.dark .contactContainer {
  background: #444;
}

.dark .contactContainer label{
  color: #d8d8d8;
}


.contactForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  }

  label {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    }

    input,
    textarea {
    padding: 5px;
    background-color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #000000;
    border-radius: 5px;
    border: solid 2px #D3D3D3;
    -webkit-transition: .5s; /* Safari */
    transition: .5s;
    }

    textarea {
      resize: none;
      }

.submitButton {
  margin-top: 15px;
  padding: 15px 25px;
  cursor: pointer;
  background-color: #00BFFF;
  border: none;
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition-duration: 0.4s;
  border-radius: 5px;
}

.submitButton:hover {
  background-color: #1E90FF;
}

@media (max-width: 768px) {
  .contactContainer {
  padding: 20px;
  }
  }

/* Estilos para el botón normal */
button {
  padding: 0px 20px;
  border: none;
  background-color: #5f47c9;
  color: white;
  transition: background-color 0.4s ease;
  overflow: hidden;
  font-size: 2rem;
}

.textLoad{
  margin: 8px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: .5s;
}

.dark .textLoad{
  color:white;
  transition: color 0.4s ease;
}

.dark .loading .textLoad{
  color:transparent;
  transition: color 0.4s ease;
}

@keyframes changeBackground {
  0% { background-color: #5f47c9; }
  100% { background-color: #000000; }
}
/* Estilos cuando el formulario se está enviando */
.loading {
  position: relative;
  color: transparent; /* Oculta el texto 'Enviar' */
  animation: changeBackground 2s forwards;
  transition: color 0.3s ease;
}

.loading::after {
  content: '🚀'; /* Emoji de cohete */
  animation: moveRocket 8s linear infinite; /* Animación para mover el cohete */
  position: absolute;
  left: 10px;
  color: red;
  transform: rotate(45deg);
  top: -7px;
  font-size: 2.5rem;
  transition: 0.4s ease;
}
@keyframes moveStars {
  0% { background-position: 0 0; }
  100% { background-position: 100px 100px; }
}
/* Animación del fondo oscuro que se desplaza */
.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -180%;
  width: 114%;
  height: 125%;
  background: radial-gradient(ellipse at center, rgb(24 26 51 / 38%) 42%, rgb(59 0 56) 108%);
  background-size: 20px 15px;
  animation: moveOverlay 18s linear infinite, moveStars 9s linear infinite;
}

.loading:hover::before {
  width: 100%;
}

/* Animación del cohete */
@keyframes moveRocket {
  0% { left: 10px; }
  100% { left: calc(100% - 30px); }
}

@keyframes moveOverlay {
  0% { left: -106%; }
  100% { left: 100%; }
}

.statusMessage{
  animation: changeOpacity .5s forwards;
  transition: opacity 0.3s ease;
}


@keyframes changeOpacity {
  0% { opacity: 0; }
  100% { opacity: 1; }
}