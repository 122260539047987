/* src/components/CustomHeader.module.css */
.customHeader {
  padding: 0.5rem;
  padding-bottom: 1.2rem;
}

.title {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.5rem;
  color: #777;
  margin: 0;
  
  margin-top:1.3rem;
  margin-bottom:1rem;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 1.1rem;
    margin-top:1.3rem;
    margin-bottom:1rem;
  }
  .title {
    font-size: 1.9rem;
  }
}
